/**
 * Header
 */
import React from 'react';
import Logo from 'photon/src/components/Logo'
import {
  HeaderWrapper,
  LogoWrapper,
  HeaderInner
} from './styles'


import {useMenu} from 'wpapi/hooks/useMenu'
import Menu from 'app/lib/components/Menu'

function Header(props) {

  const menu = useMenu('primary')

  return (
      <>
        <HeaderWrapper>
          <HeaderInner flex={1}>
            <LogoWrapper>
              <Logo link />
            </LogoWrapper>
            {0 < menu.length && <Menu items={menu} />}
          </HeaderInner>
        </HeaderWrapper>
      </>
  );
}

export default Header
