import React from 'react';
import AppRouter from './router'
import Spectra from 'spectra'
import './styles/main.scss'
import Header from './parts/Header'
import Footer from './parts/Footer'
import {useSite} from 'wpapi/hooks/useSite'
import {ThemeProvider} from 'styled-components'
import photonTheme from 'app/theme'
import {GlobalStyle} from './parts/GlobalStyles'
import {firebaseConfig} from '../firebaseConfig'

function App() {
  const site = useSite()

  return (
      <ThemeProvider theme={photonTheme}>
        <GlobalStyle />
        <Spectra config={firebaseConfig}>
          <Header />
          <AppRouter />
          <Footer {...(site && site.options && site.options.footer)} />
        </Spectra>
      </ThemeProvider>
  );
}

export default App;
