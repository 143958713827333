import React, {useState} from 'react';
import RespondTo from 'photon/src/components/RespondTo'
import {
  MenuWrapper,
  MenuItem,
  ItemsWrapper,
  MenuToggle
} from './styles'

function Menu({items}) {

  const [toggle, setToggle] = useState(false)

  const outputMenu = () => (
    <ItemsWrapper>
      {items && items.map((item, idx) => (
        <MenuItem key={item.ID}>
          <a href={item.url} onClick={() => {toggle && setToggle(false)}}>
            {item.title}
          </a>
        </MenuItem>
      ))}
    </ItemsWrapper>
  );

  const mobileNav = () => (
    <>
      <MenuToggle onClick={() => setToggle(!toggle)} />
      {toggle && outputMenu()}
    </>
  )

  return (
    <MenuWrapper>

      <RespondTo
        mobile={mobileNav}
        tablet={mobileNav}
        desktop={outputMenu}
      />

    </MenuWrapper>
  );
}

export default Menu;
