import React from 'react'
import Image from 'photon/src/components/Image'
import {getTheme} from 'photon'

const logo = getTheme('logo')

export default function Logo({link, inline}) {

  if (link) {
    return (
      <a href={'/'}>
        <Image {...logo} />
      </a>
    );
  }

  return (
    <Image {...logo} />
  );
}
