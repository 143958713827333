import axios from 'axios'
import {getEndpoint, parseURL} from '../helpers'

class Portfolio {

  static list = async (args) => {
    try {
      const url = parseURL(getEndpoint('jetpack-portfolio'), args)
      const response = await axios.get(url)
      return response.data
    } catch(err) {
      console.error(err);
    }
  }

  static getBySlug = async (slug) => {
    try {
      const url = parseURL(getEndpoint('jetpack-portfolio'), {slug})
      const response = await axios.get(url)
      return response.data
    } catch(err) {
      console.error(err);
    }
  }
}

export default Portfolio
