import axios from 'axios'
import {getEndpoint, parseURL} from '../helpers'

class Menus {

  static getByLocation = async (location) => {
    try {
      const url = parseURL(getEndpoint('menus', 'prt'), {location})
      const response = await axios.get(url)
      return response.data
    } catch(err) {
      console.error(err);
    }
  }
}

export default Menus
