import styled from 'styled-components'

export const TextWrapper = styled.p.attrs(props => ({
  className: `photon-text ${props.className}`
}))`
  color: ${props => props.color || 'inherit'};

  ${props => props.pt && `padding-top: ${props.pt};`}
  ${props => props.pl && `padding-left: ${props.pl};`}
  ${props => props.pr && `padding-right: ${props.pr};`}
  ${props => props.pb && `padding-bottom: ${props.pb};`}

  ${props => props.mt && `margin-top: ${props.mt};`}
  ${props => props.ml && `margin-left: ${props.ml};`}
  ${props => props.mr && `margin-right: ${props.mr};`}
  ${props => props.mb && `margin-bottom: ${props.mb};`}

  ${props => props.size && `font-size: ${props.size};`};
`
