import { useState, useEffect } from 'react';

import wpapi from '../'

export const useMenu = (location) => {

  const [menu, setMenu] = useState([])

  useEffect(() => {
    // let __OkToLoad = true;
    const _loadMenu = async () => {
      const response = await wpapi.menus.getByLocation(location)
      if (response
        && Array.isArray(response)
        && 0 < response.length) {
        setMenu(response)
      }
    }

    _loadMenu();

    // return () => {__OkToLoad = false}
  }, [location])

  return menu
}
