import styled from 'styled-components'
import Box from 'photon/src/components/Box'
import Text from 'photon/src/components/Text'
import Container from 'photon/src/components/Container'

import {getTheme, respondTo} from 'photon'

// const colors = getTheme('colors', {})
const media = getTheme('media', {})

export const FooterWrapper = styled.footer`
  padding-top: 4em;

  background-position: center;
  background-size: initial;
  background-repeat: no-repeat;
`

export const Copyright = styled(Text)`
  margin: 0 0 1em;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: center;
  color: inherit;
`

export const FooterInner = styled(Container)`
  padding: 10px;
  justify-content: space-between;
  /* align-items: center; */
  flex-direction: column;

  ${respondTo(media[0])} {
    flex-direction: row;
  }
`

export const FooterCol = styled(Box)`
  h5 {
    margin-bottom: 1.5em;
  }

  ${respondTo(media[1], 'max')} {
    margin-bottom: 2em;
  }
`

export const Newsletter = styled(Box)`
  margin: 0;
`
