import { useState, useEffect } from 'react';

const __initialState = {w: 0, h: 0};

export const useWindowResize = () => {

  const [windowSize, setWindowSize] = useState(__initialState)

  useEffect(() => {
    const _resize = (e) => {
      const {innerWidth,innerHeight} = window;
      setWindowSize({
        w: innerWidth,
        h: innerHeight
      })
    }
    _resize();
    window.addEventListener('resize', _resize);

    return () => {
      window.removeEventListener('resize', _resize);
    }
  }, [])

  return windowSize
}
