/**
 * Photon
 */
import theme from 'app/theme'

/**
 * Get any property of the theme object
 *
 * @param  {String} key           the key for the property to retrieve
 * @param  {mixed} [fallback='']  the fallback or default you want if the property is not found
 * @return {mixed}                the value for the property
 */
export const getTheme = (key, fallback = '') => (theme[key] || fallback)

/**
 * deprecated, keeps backward support as alias of getTheme
 *
 * @deprecated
 */
export const useTheme = getTheme

/**
 * Sets the widths for the content container using media quiries from the theme
 *
 * @param {Array} [widths=[]] The widths to set, starting with mobile
 */
export const setMediaWidths = (widths = []) => {
  if (!widths.length) return '';
  return (mediaStyles('width', widths))
}

/**
 * Insert css styles as media quieries based on theme's media settings
 *
 * @param  {String} property    the property to set -- i.e. width | padding | display
 * @param  {Array}  [styles=[]] the values for the property per media size, starting with mobile
 * @return {String}             the css to use for the responsive styles
 */
export const mediaStyles = (property, styles = []) => {
  if (!property && !styles.length) return '';
  // mobile first styles
  let _css = `${property}: ${styles[0]};`
  // media queries
  for (var i = 0; i < theme.media.length; i++) {
    if (theme.media.length < (i + 1)) continue;
    if (!styles[i + 1]) continue;
    _css += `${respondTo(theme.media[i])} {
      ${property}: ${styles[i + 1]};
    }`;
  }
  return _css;
}

/**
 * Insert a media query into css
 *
 * @param  {mixed} size           the size to respond to - i.e. 600px | 90vw
 * @param  {String} [minMax='min'] whether to respond to max or min width logic
 * @return {String}                the css to use for the css block media query
 */
export const respondTo = (size, minMax = 'min') => (
  `@media screen and (${minMax}-width: ${size}) `
)
