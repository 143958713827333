import React from 'react';
import HubspotForm from 'react-hubspot-form'
// import {useMenu} from 'wpapi/hooks/useMenu'
// import Menu from '../../lib/components/FooterMenu'
import Logo from 'photon/src/components/Logo'
// import Heading from 'photon/src/components/Heading'

import {
  Copyright,
  FooterCol,
  Newsletter,
  FooterInner,
  FooterWrapper,
} from './styles'

function Footer({background_color, background_image}) {

  // const menu1 = useMenu('footer_1')
  // const menu2 = useMenu('footer_2')
  // const menu3 = useMenu('footer_3')

  return (<FooterWrapper
      style={{
        backgroundColor: background_color,
        backgroundImage: `url(${(background_image && background_image.url) || ''})`,
      }}
    >
    <FooterInner flex={1}>
      <FooterCol
        flex
        flexBox={{flex: 1}}
        widths={['100%', '30%']}
      >
        <Logo link />
      </FooterCol>

      <FooterCol
        flex
        flexBox={{flex: 1}}
      >
        <Newsletter>
          <HubspotForm
            portalId={"8327322"}
            formId={"cbac29c4-826d-4ffd-afb4-213ca7dec7f2"}
          />
        </Newsletter>
      </FooterCol>
      {
        // <FooterCol
        //   flex
        //   flexBox={{flex: 1}}
        // >
        //   <Heading as={'h5'}>Our Products</Heading>
        //   {menu2 && <Menu items={menu2} stacked />}
        // </FooterCol>
        // <FooterCol
        //   flex
        //   flexBox={{flex: 1}}
        // >
        //   <Heading as={'h5'}>About Photon</Heading>
        //   {menu3 && <Menu items={menu3} stacked />}
        // </FooterCol>
      }
    </FooterInner>
    <Copyright>
      &copy; Spectra. All Rights Reserved.
    </Copyright>
    <Copyright>
      Spectra is a product offered by Photon Software.
    </Copyright>
  </FooterWrapper>);
}

export default Footer
