import { useState, useEffect } from 'react';

import wpapi from '../'

export const useSite = (slugOrId) => {

  const [site, setSite] = useState(null)

  useEffect(() => {

    let __OkToLoad = true;

    const _loadSite = async () => {
      const response = await wpapi.site.get()

      if (response) {
        setSite(response)
      }
    }

    __OkToLoad && _loadSite();

    return () => {__OkToLoad = false}
  }, [slugOrId])

  return site
}
