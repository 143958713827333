import React from 'react';
import styled from 'styled-components'
import Box from 'photon/src/components/Box'
import {getTheme, respondTo} from 'photon'
// import Box from 'photon/src/components/Box'

const colors = getTheme('colors', {});
const media = getTheme('media', []);

export const MenuWrapper = styled(Box)`
  display: flex;
  /* flex: 1; */
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const ItemsWrapper = styled.ul`
  display: flex;
  /* flex: 1; */
  justify-content: center;
  align-items: center;

  ${respondTo(media[1], 'max')} {
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    width: 15em;
    height: auto;
    justify-content: flex-start;
    align-items: flex-start;
    background: ${colors.white};
    padding: 1em;
    box-shadow: 0 0.125em 0.5em 0.125em ${colors.lightGray};
    border-radius: 0.25em;

    li {
      line-height: 2;
    }
  }
`;

export const MenuItem = styled.li`
  margin: 0 56px 0 0;
  list-style: none;

  &:last-child {
    margin: 0;
  }

  a {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: ${colors.gray};
    text-decoration: none;
    &:hover {
      color: ${colors.purple};
    }
  }
`;


export const Bars = styled(Box)`
  width: 40px;
  cursor: pointer;

  span {
    display: block;
    width: 100%;
    height: 0.25em;
    background: ${colors.gray};
    margin-bottom: 0.25em;
    border-radius: 0.25em;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const MenuToggle = (props) => (
  <Bars {...props}>
    <span />
    <span />
    <span />
  </Bars>
)
