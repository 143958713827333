/**
 * Spectra
 */
import React, {useEffect} from 'react';

function royGBiv(r, o, y, g, b, i, v){
  r.__spectraBaseUrl = y;r.__spectraConfig = b;
  i = o.createElement('script');i.src = y+g;i.async = 1;
  v = o.getElementsByTagName('head')[0];v.appendChild(i);
};

function Spectra({config, children}) {
  useEffect(() => {
    royGBiv(window, document, 'https://spectrajs.com', '/stats/', config);
  }, [config])

  return (<>
    {children}
  </>)
}

export default Spectra;
