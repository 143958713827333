import {base, namespaces} from '../'

export const getEndpoint = (endpoint, ns = 'wp') => {
  if (!namespaces[ns]) {
    throw new Error('The namespace provided does not exist.');
  }
  return `${base}${namespaces[ns]}/${endpoint}`;
};

export const parseURL = (url, args = {}) => {
  let _url = `${url}?`
  for (var key in args) {
    if (args.hasOwnProperty(key)) {
      _url += `${key}=${args[key]}&`
    }
  }
  return _url.substr(0, (_url.length - 1));
};
