import axios from 'axios'
import {getEndpoint, parseURL} from '../helpers'

class Site {

  static get = async () => {
    try {
      const url = parseURL(getEndpoint('site', 'prt'), {})
      const response = await axios.get(url)
      return response.data
    } catch(err) {
      console.error(err);
    }
  }
}

export default Site
