import axios from 'axios'
import {getEndpoint} from '../helpers'

class Media {

  static getById = async (id) => {
    try {
      const url = `${getEndpoint('media')}/${id}`
      const response = await axios.get(url)
      return response.data
    } catch(err) {
      console.error(err);
    }
  }
}

export default Media
