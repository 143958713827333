export const firebaseConfig = {
  apiKey: "AIzaSyAm6BxUkl2Rx2GhqeMfgBOkB404JIwM920",
  authDomain: "spectra-66e68.firebaseapp.com",
  databaseURL: "https://spectra-66e68.firebaseio.com",
  projectId: "spectra-66e68",
  storageBucket: "spectra-66e68.appspot.com",
  messagingSenderId: "20057826382",
  appId: "1:20057826382:web:b88fa9d4d99a99036c0f1f",
  measurementId: "G-4PK6J5QDDK"
}
