import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import loadable from '@loadable/component'
const _screens = './screens'
// Routes
const Page = loadable(() => import(`${_screens}/Page`))
const Home = loadable(() => import(`${_screens}/Home`))

export default function AppRouter() {
  return (
    <Router>
      {
        //<ScrollToTopAuto />
      }
      <Switch>
        {/* HomePage */}
        <Route exact path="/">
          <Home />
        </Route>

        {/* App Page */}
        <Route exact path="/:slug">
          <Page />
        </Route>

      </Switch>
    </Router>
  );
}
