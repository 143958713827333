/**
 * RespondTo
 */
import {useEffect, useState} from 'react'

import {useWindowResize} from '../../hooks/useWindowResize'
import {useTheme} from 'photon'
import {getMediaAsIntegers} from 'photon/src/helpers/theme'

const media = getMediaAsIntegers(useTheme('media'));

export default function RespondTo(props) {

  const winSize = useWindowResize();
  const [device, setDevice] = useState();

  useEffect(() => {

    if (winSize.w < media[0]) {
      setDevice('mobile')
    } else
    if (winSize.w > media[0] &&
      winSize.w < media[1]) {
      setDevice('tablet')
    } else
    if (winSize.w > media[1]) {
      setDevice('desktop')
    }
  }, [winSize])

  if (props.hasOwnProperty(device)) {
    return (props[device]())
  }

  return null;
}
