import axios from 'axios'
import {getEndpoint, parseURL} from '../helpers'

class Posts {

  static list = async (args) => {
    try {
      const url = parseURL(getEndpoint('posts'), args)
      // console.log(url);
      const response = await axios.get(url)
      return response.data
    } catch(err) {
      console.error(err);
    }
  }

  static getById = async (id) => {
    try {
      const url = `${getEndpoint('posts')}/${id}`
      const response = await axios.get(url)
      return response.data
    } catch(err) {
      console.error(err);
    }
  }

  static getBySlug = async (slug) => {
    try {
      const url = parseURL(getEndpoint('posts'), {slug})
      const response = await axios.get(url)
      return response.data
    } catch(err) {
      console.error(err);
    }
  }
}

export default Posts
